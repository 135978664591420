import mitt, { type Emitter } from "mitt";

type Events = {
  "drop-irr-range": [number | undefined, number | undefined];
  "drop-apr-range": [number | undefined, number | undefined];
  "drop-price-range": [number | undefined, number | undefined];
  "drop-min-invest-range": [number | undefined, number | undefined];
  "drop-checkbox-filters": void;
  "update-price-range": void;
  "change-filters": any;
  "reset-filters": void;
  "compare-counter": number;
  "update-checkbox-filters": void;
  "call-fixed-action-butt": {
    text: string;
    action: () => void;
    timeOut?: number;
    position: { x: number; y: number };
  };
};

const eventBus: Emitter<Events> = mitt<Events>();

export default eventBus;
